import React, { useState } from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import './Auth.css'
import { ReactComponent as Logo } from '../../media/logo.svg';
import GoogleLogo from '../../media/google.png';
import { login } from '../../features/account/accountSlice'
import { history } from '../../helpers/history';
import { AMPLITUDE_EVENTS } from '../../consts'


export default function AuthPage() {
  const authStatus = useSelector(state => state.account.status);
  const isAuthenticated = useSelector(state => state.account.isAuthenticated);
  const surveyShown = useSelector(state => state.account.surveyShown);
  const [isOpenedAmplitudeTracked, setIsOpenedAmplitudeTracked] = useState(false);
  const dispatch = useDispatch()
  const googleLogin = useGoogleLogin({
    onSuccess: credentialResponse => {
      amplitude.track(AMPLITUDE_EVENTS.clickGoogleAuth)
      if (credentialResponse.code){
        dispatch(login(credentialResponse.code))
      }
    },
    flow: 'auth-code',
  });

  if (authStatus === 'loading') {
    return null
  }
  else if (authStatus === 'succeeded' && isAuthenticated) {
    if (surveyShown) {
      return <Navigate to="/" state={{ from: history.location }} />
    }
    return <Navigate to="/surveys/about-me/" state={{ from: history.location }} />
  }
  if (!isOpenedAmplitudeTracked) {
    amplitude.track(AMPLITUDE_EVENTS.authPageShow);
    setIsOpenedAmplitudeTracked(true)
  }
  return (
    <div className='auth'>
      <div className="logo">
        <Logo />
      </div>
      <div className="content">
        <div className="title">Log  in or sign up</div>
        <div className="buttons-container">
          <button className="btn-auth" onClick={() => googleLogin()}>
            <img className="btn-icon" src={GoogleLogo}/>
            Continue with Google
          </button>
        </div>
        <div className="therm-policy">
          By continuing, you acknowledge that you have read and
          understood, and agree to Linkify <a className="therm-link" href="https://www.linkify.ai/terms" target="__blank">
          Terms of Service</a> and <a className="therm-link" href="https://www.linkify.ai/privacy" target="__blank">Privacy Policy</a>
        </div>
      </div>
    </div>
  )
}

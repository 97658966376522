import * as React from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as amplitude from '@amplitude/analytics-browser';
import Root from '../../routes/root'
import { AMPLITUDE_API_KEY } from '../../consts'


import { BrowserRouter } from "react-router-dom";

export default function App() {
  amplitude.init(AMPLITUDE_API_KEY);
  return (
    <>
      <GoogleOAuthProvider clientId="666686287196-4rmu60ahjjgqrt1ah40igj9emannpiue.apps.googleusercontent.com">
        <BrowserRouter>
          <Root/>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {GridLoader} from 'react-spinners';
import * as amplitude from '@amplitude/analytics-browser';
import Header from '../../components/header/Header'
import { fetchProjects } from '../../features/projects/projectsSlice'
import ShortProjectCard from '../../components/ShortProjectCard/ShortProjectCard'
import AddNewProjectCard from '../../components/AddNewProjectCard/AddNewProjectCard'
import PopupCard from '../../components/PopupCard/PopupCard'
import { STATUS, DEFAULT_SPINNER_COLOR } from '../../consts';
import { AMPLITUDE_EVENTS } from '../../consts';
import './Projects.css';


export default function ProjectsPage() {
  const projectsStatus = useSelector(state => state.projects.status);
  const projects = useSelector(state => state.projects.projects);
  const [isOpenedAmplitudeTracked, setIsOpenedAmplitudeTracked] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(state => state.project.error)

  useEffect(() => {
    if (projectsStatus === STATUS.init) {
      dispatch(fetchProjects())
    }
  }, [projectsStatus, dispatch])

  if (projectsStatus === STATUS.loading) {
    return (
      <>
        <Header/>
        <div className="container-projects">
          <div className="page-title">Projects</div>
          <div className="project-wrapper">
            <GridLoader color={DEFAULT_SPINNER_COLOR}/>
          </div>
        </div>
      </>
    )
  }
  if (!isOpenedAmplitudeTracked) {
    amplitude.track(AMPLITUDE_EVENTS.mainProductPageShow);
    setIsOpenedAmplitudeTracked(true)
  }

  return (
    <>
      <Header/>
      {(projectsStatus === STATUS.error) &&
        <PopupCard msg={error}/>
      }
      <div className="container-projects">
        <div className="page-title">Projects</div>
        <div className="project-wrapper">
          <AddNewProjectCard/>
          {projects.map((project) => (
            <ShortProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </>
  )
}

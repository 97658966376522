import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../helpers/client'

const initialState = createInitialState()

function createInitialState() {
  var savedAccountInfo = localStorage.getItem('account')
  if (!savedAccountInfo){
    return {
      name: '',
      picture: '',
      isAuthenticated: false,
      status: 'init',
      error: '',
      balance: '0.0',
      surveyShown: false
    }
  }
  return JSON.parse(savedAccountInfo)
}

export const login = createAsyncThunk('account/login', async (credential) => {
  const response = await client.post('/auth?code='+ credential)
  return response.data
})

export const getAccount = createAsyncThunk('account/me', async() => {
  const response = await client.get('/me')
  return response.data
})

export const logout = createAsyncThunk('account/logout', async () => {
  const response = await client.post('/logout')
  return response.data
})

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.name = action.payload.name
        state.picture = action.payload.picture
        state.balance = action.payload.balance
        state.isAuthenticated = true
        state.surveyShown = action.payload.survey_shown
        localStorage.setItem('auth', JSON.stringify(state));
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(getAccount.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.name = action.payload.name
        state.picture = action.payload.picture
        state.balance = action.payload.balance
        state.isAuthenticated = true
        state.surveyShown = action.payload.survey_shown
        localStorage.setItem('auth', JSON.stringify(state));
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isAuthenticated = false
        state.name = ''
        state.picture = ''
        state.balance = '0.0'
        state.surveyShown = false
        localStorage.removeItem('auth');
      })
  }
})


export const { loginAccount, logoutAccount } = accountSlice.actions

export default accountSlice.reducer

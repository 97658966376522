import { ERROR_MSG_DEFAULT } from '../consts'


function getServiceUrl() {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    return 'http://localhost:8000/api'
  } else {
    return 'https://app.linkify.ai/api'
  }
};

export async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
  const service_url = getServiceUrl();

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    mode: "cors",
    credentials: 'include',
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(service_url + endpoint, config)
    data = await response.json()
    if (response.ok) {
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    console.log(response.status)
    if (response.status >= 500) {
      throw new Error(ERROR_MSG_DEFAULT)
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(ERROR_MSG_DEFAULT)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'POST' })
}

client.patch = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PATCH' })
}

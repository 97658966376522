import { configureStore } from '@reduxjs/toolkit'
import AccountReducer from '../features/account/accountSlice'
import ProjectsReducer from '../features/projects/projectsSlice'
import ProjectReducer from '../features/project/projectSlice'


export default configureStore({
  reducer: {
    'account': AccountReducer,
    'projects': ProjectsReducer,
    'project': ProjectReducer,
  }
});

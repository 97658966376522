import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { ReactComponent as CloseIcon } from '../../media/CloseIcon.svg';
import './PopupCard.css'

export default function PopupCard(props) {
  const [open, setOpen] = useState(true)
  return (
    <Popup setOpen={setOpen} open={open} position='top center' role='dialog'>
      <span>{props.msg}</span>
      <CloseIcon onClick={() => {setOpen(false)}} style={{cursor: 'pointer'}}/>
    </Popup>
  )
}
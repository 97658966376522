import $ from 'jquery';

export function replaceKeywordToLinkInText(editorText, keyword, link){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      var keywordRegExp = new RegExp('(|[\\s.,!?:\\(\\)\\{\\}\\/>]|)('+ keyword+')([\\s.,!?:\\(\\)\\{\\}\\/<]|$)', "gi")
      var stringToReplace = '<a href="'+link+'" class="linkifyLink">$2</a>'
      return {
        ...block,
        "data": {
          ...block.data,
          "text": block.data.text.replaceAll(keywordRegExp, "$1"+stringToReplace+"$3")
        }
      }
    })
  }
}

export function removeLinks(editorText){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      var text = $.parseHTML('<p>' + block.data.text + '</p>')
      $(text[0]).find('a[class^="linkifyLink"]').contents().unwrap()
      return {
        ...block,
        "data": {
          ...block.data,
          "text": text[0].innerHTML
        }
      }
    })
  }
}


export function removeLink(editorText, linkToRemove){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      var text = $.parseHTML('<p>' + block.data.text + '</p>')
      $(text[0]).find('a.linkifyLink[href^="'+linkToRemove + '"]').contents().unwrap()
      return {
        ...block,
        "data": {
          ...block.data,
          "text": text[0].innerHTML
        }
      }
    })
  }
}


export function replaceLinkInText(editorText, oldLink, newLink){
  if (!editorText) {
    return editorText
  }

  return {
    ...editorText,
    blocks: editorText.blocks.map((block) => {
      var linkRegExp = '<a href="'+oldLink+'" class="linkifyLink">'
      var stringToReplace = '<a href="'+newLink+'" class="linkifyLink">'
      return {
        ...block,
        "data": {
          ...block.data,
          "text": block.data.text.replaceAll(linkRegExp, stringToReplace)
        }
      }
    })
  }
}

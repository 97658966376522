export const STATUS = {
  init: 'init',
  loading: 'loading',
  error: 'error',
  succeeded: 'succeeded'
}

export const ERROR_MSG_DEFAULT = 'Oups! We`ve already running to fix it!'

export const DEFAULT_SPINNER_COLOR = '#3761f6'

export const AMPLITUDE_API_KEY = '2a0d8079f9f92eb6e943263f44949e2c'

export const AMPLITUDE_EVENTS = {
  authPageShow: 'auth_page_show',
  clickGoogleAuth: 'click_google_auth',
  quizPageShow: 'quiz_page_show',
  mainProductPageShow: 'main_product_page_show',
  clickCreateProject: 'click_create_project',
  projectView: 'project_view',
  clickCopyText: 'click_copy_text',
  clickGetLinks: 'click_get_links',
}

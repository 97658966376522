import React from "react";
import { NavLink, Navigate } from "react-router-dom";
import { ReactComponent as LinkIcon } from '../../media/link.svg';


export default function ShortProjectCard(project) {
  return (
    <NavLink to={'/projects/'+project.project.id+'/'} className="project-block">
      <div className="project-title">{project.project.name}</div>
      <div className="project-description">
        {project.project.short_text}
      </div>
      <div className="project-footer">
        <LinkIcon/>
        {project.project.count_links} links
      </div>
    </NavLink>
  )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../../helpers/client'
import { STATUS } from '../../consts'

const initialState = {
  status: STATUS.init,
  error: '',
  statusAddingProject: STATUS.init,
  errorAddingProject: '',
  projects: []
}


export const fetchProjects = createAsyncThunk('projects/fetchProjects', async() => {
  const response = await client.get('/projects/')
  return response.data
})

export const addProject = createAsyncThunk('projects/createProject', async(projectName) => {
  const response = await client.post('/projects/', {'name': projectName})
  return response.data
})


const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state, action) => {
        state.status = STATUS.loading
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = STATUS.succeeded
        state.projects = state.projects.concat(action.payload);
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = STATUS.error
        state.error = action.error.message
      })
      .addCase(addProject.pending, (state, action) => {
        state.statusAddingProject = STATUS.loading
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.statusAddingProject = STATUS.succeeded
        state.projects.unshift(action.payload);
      })
      .addCase(addProject.rejected, (state, action) => {
        state.statusAddingProject = STATUS.error
        state.errorAddingProject = action.error.message
      })
  }
})

export default projectsSlice.reducer

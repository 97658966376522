import React from 'react';
import {Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
import AuthPage from '../pages/auth/Auth'
import ProjectsPage from '../pages/projects/Projects'
import ProjectPage from '../pages/project/Project'
import SurveyPage from '../pages/survey/Survey'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import { history } from '../helpers/history'

export default function Root(){
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Routes>
      <Route element={<PrivateRoute />} >
        <Route path="/" element={<ProjectsPage />} />
        <Route path="/projects/" element={<ProjectsPage />} />
        <Route path="/projects/:projectId/" element={<ProjectPage />} />
        <Route path="/surveys/about-me/" element={<SurveyPage/>} />
      </Route>
      <Route path="/auth/" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}